var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
    [
      _vm.thoughts.length
        ? _vm._l(_vm.thoughts, function(thought) {
            return _c(
              "v-flex",
              { key: thought.id, attrs: { xs12: "" } },
              [
                _c("thought-card", {
                  attrs: { thought: thought },
                  on: { edit: _vm.onEdit, delete: _vm.onDelete }
                })
              ],
              1
            )
          })
        : [
            _c("v-flex", { attrs: { xs12: "" } }, [
              _c("h3", { staticClass: "starling-h3 StarlingDarkGrey--text" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "app.tools.thought_balancer.tabs.history.empty.title"
                    )
                  )
                )
              ])
            ]),
            _c("v-flex", { attrs: { xs12: "" } }, [
              _c("p", { staticClass: "starling-body primary--text" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "app.tools.thought_balancer.tabs.history.empty.subtitle"
                    )
                  )
                )
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }