var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "thoughts-card",
      attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                wrap: "",
                "justify-center": "",
                "align-center": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "", md4: "" } },
                [
                  _c("v-icon", { staticClass: "primary--text" }, [
                    _vm._v("$vuetify.icons.starling_thought_balancer")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _vm.active === 0
          ? _c("p", {
              staticClass: "starling-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("app.tools.thought_balancer.tabs.tool.motivational")
                )
              }
            })
          : _vm._e(),
        _vm.active === 1
          ? _c("p", {
              staticClass: "starling-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("app.tools.thought_balancer.tabs.history.motivational")
                )
              }
            })
          : _vm._e()
      ]),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                "fixed-tabs": "",
                centered: "",
                grow: "",
                touchless: "",
                "slider-color": "primary"
              },
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            [
              _c(
                "v-tab",
                {
                  key: "balancer",
                  staticClass: "text-capitalize",
                  attrs: { ripple: "" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("app.tools.thought_balancer.tabs.tool.title")
                      ) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "v-tab-item",
                { key: "balancer" },
                [
                  _c("thought-form", {
                    staticClass: "py-3",
                    attrs: { thought: _vm.form, loading: _vm.loading },
                    on: { "thought-submit": _vm.createThought }
                  })
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  key: "history",
                  staticClass: "text-capitalize",
                  attrs: { ripple: "" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("app.tools.thought_balancer.tabs.history.title")
                      ) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "v-tab-item",
                { key: "history" },
                [_c("all-thoughts", { staticClass: "scale-in-ver-top py-3" })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }