<template>
<v-layout row wrap gap-xs-3>
  <template v-if="thoughts.length">
    <v-flex xs12 v-for="thought in thoughts" :key="thought.id">
      <thought-card :thought="thought" @edit="onEdit" @delete="onDelete"></thought-card>
    </v-flex>
  </template>

  <template v-else>
    <v-flex xs12>
      <h3 class="starling-h3 StarlingDarkGrey--text">{{$t('app.tools.thought_balancer.tabs.history.empty.title')}}</h3>
    </v-flex>
    <v-flex xs12>
      <p class="starling-body primary--text">{{$t('app.tools.thought_balancer.tabs.history.empty.subtitle')}}</p>
    </v-flex>
  </template>
</v-layout>
</template>

<script>
import thoughtCard from '@/views/tools/thoughts/components/thought-card.vue';

export default {
  name: 'thoughtsView',
  components: {
    thoughtCard,
  },
  data() {
    return {
      showError: false,
    };
  },
  computed: {
    thoughts() {
      return this.$store.getters['thoughts/thoughts'];
    },
  },
  methods: {
    onEdit(thought) {
      this.$router.push({ name: 'thoughts-edit', params: { id: thought.id } });
    },
    onDelete(thought) {
      const successMessage = this.$t('app.tools.thought_balancer.notifications.deleted');
      this.$store.dispatch('thoughts/deleteThought', thought.id)
        .then(() => {
          this.$store.commit('setNotification', { text: successMessage });
        });
    },
  },
};
</script>
