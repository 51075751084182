<template>
<v-layout row wrap pt-3 gap-xs-3 class="thoughts-card">
  <v-flex xs12>
    <v-layout row wrap justify-center align-center>
      <v-flex xs6 md4>
        <v-icon class="primary--text">$vuetify.icons.starling_thought_balancer</v-icon>
      </v-flex>
    </v-layout>
  </v-flex>

  <v-flex xs12>
    <p class="starling-text" v-if="active === 0" v-html="$t('app.tools.thought_balancer.tabs.tool.motivational')"></p>
    <p class="starling-text" v-if="active === 1" v-html="$t('app.tools.thought_balancer.tabs.history.motivational')"></p>
  </v-flex>

  <v-flex xs12>
    <v-tabs v-model="active" fixed-tabs centered grow touchless slider-color="primary">
      <v-tab :key="'balancer'" ripple class="text-capitalize">
        {{$t('app.tools.thought_balancer.tabs.tool.title')}}
      </v-tab>

      <v-tab-item :key="'balancer'">
        <thought-form class="py-3" :thought="form" @thought-submit="createThought" :loading="loading"></thought-form>
      </v-tab-item>

      <v-tab :key="'history'" ripple class="text-capitalize">
        {{$t('app.tools.thought_balancer.tabs.history.title')}}
      </v-tab>

      <v-tab-item :key="'history'">
        <all-thoughts class="scale-in-ver-top py-3"></all-thoughts>
      </v-tab-item>
    </v-tabs>
  </v-flex>
</v-layout>
</template>

<script>
import allThoughts from '@/views/tools/thoughts/components/all-thoughts.vue';
import ThoughtForm from './components/thought-form';

export default {
  name: 'ThoughtsView',
  components: {
    ThoughtForm,
    allThoughts,
  },
  data() {
    return {
      active: this.$route.query.tab ? parseInt(this.$route.query.tab) : 0,
      loading: false,
      form: {},
    };
  },
  computed: {
    thoughts() {
      return this.$store.getters['thoughts/thoughts'];
    },
  },
  watch: {
    active(newValue) {
      this.$log.debug('Tab changed to ', newValue);
      this.form = {};
      this.$router.replace({ query: { tab: newValue } });
      document.getElementsByClassName('v-toolbar')[0].scrollIntoView();
    },
  },
  mounted() {
    this.$store.dispatch('thoughts/getThoughts');
  },
  methods: {
    createThought(event) {
      this.loading = true;
      this.$store.dispatch('thoughts/postThought', event)
        .then(res => {
          this.active = 1;
          this.$store.commit('setNotification', { text: this.$t('app.tools.thought_balancer.notifications.created') });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
